import {httpClient} from "@/utils";

export const GetList = function(limit, page, keyword, filters) {
    return httpClient.get('lead/list', { params: { limit, page, keyword, filters } } );
}

export const ChangeGroup = function(uid, new_group) {
    return httpClient.put('lead/change-group', { uid, new_group });
}

export const AddToGroup = function(uid, new_group, expiration_date) {
    return httpClient.put('lead/add-to-group', { uid, new_group, expiration_date });
}

export const UnsubscribeFromGroup = function(uid, guid) {
    return httpClient.put('lead/unsubscribe-from-group', { uid, guid });
}

export const SendMessage = function(uid, msg, opt) {
    return httpClient.post('lead/send-msg', { uid, msg, opt });
}

export const ChangeName = function(uid, name) {
    return httpClient.put('lead/change-name/' + uid, { name });
}

export const Unsubscribe = function(uid) {
    return httpClient.delete('lead/unsubscribe/' + uid);
}
