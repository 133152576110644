<template lang="pug">
	.widget-container
		el-row(:gutter="24")
			el-col(:xs="24")
				el-card.box-card
					.clearfix.d-flex.j-between(slot="header")
						h2.no-line-height.text-left Users
						.extra
							el-tooltip(class="item" effect="dark" content="Add New User" placement="top-start")
								font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable.text-info(:icon="['fa', 'plus']" @click="$router.push('/user/create')")

					el-row.mar.t-1(:gutter="24")
						el-col(:xs="24" :sm="8")
							el-input(v-model="paginate.keyword" size="mini" placeholder="Search" v-debounce:300ms="fetchData")
					el-divider
					el-row.mg.b0-5()
						el-col(:span="24")
							el-pagination.mar.a-1.text-left(background="" 	@size-change="fetchData" @current-change="fetchData" :current-page.sync="paginate.page" :page-sizes="[10, 20, 30, 40]" :page-size.sync="paginate.limit" layout="prev, pager, next, sizes " :total="count")
					el-table(id="data-list", v-loading='busy', :data='list', element-loading-text='Loading', border='', fit='', highlight-current-row='')
						el-table-column(align='center', label="#" width='180')
							template(slot-scope='scope')
								span {{ scope.row.id }}
						el-table-column(align='center', label="Full Name")
							template(slot-scope='scope')
								span {{ scope.row.fullName || '-' }}
						el-table-column(align='center', label="Username")
							template(slot-scope='scope')
								span {{ scope.row.username || '-' }}
						el-table-column(align='center', label="E-Mail")
							template(slot-scope='scope')
								span {{ scope.row.email || '-' }}
						el-table-column(align='center', label="Actions" :width="100")
							template(slot-scope='scope')
								el-tooltip(class="item" effect="dark" content="Edit Group" placement="top-start")
									font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'user-alt']"  @click="$router.push('/user/edit/' + scope.row.id)")
								el-tooltip(class="item" effect="dark" content="Remove" placement="top-start")
									font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'trash-alt']" @click="remove(scope.row.id)")



</template>

<style lang="scss">

</style>

<script>
import {DeleteEntity, GetList} from "@/api/user";
import {UnsubscribeFromGroup} from "@/api/lead";

export default {
	data() {
		return {
			busy: false,
			list: [],
			count: 0,
			paginate: {
				limit: 10,
				page: 1,
				keyword: ''
			}
		}
	},
	created() {
		this.fetchData();
	},
	methods: {
		fetchData() {
			GetList(this.paginate.limit, this.paginate.page, this.paginate.keyword)
					.then(res => {
						this.list = res.list;
						this.count = res.count;
					});
		},
		remove(uid) {
			const that = this;
			this.$confirm('Are you sure that you want to remove this user?', 'Warning', {
				confirmButtonText: 'Remove',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(async () => {
				try {
					let result = await DeleteEntity(uid);
					this.fetchData();
					this.$notify.success({
						title: 'Action Status',
						message: 'Removed'
					});
				} catch (e) {
					console.error(e)
					that.$notify.warning({
						title: 'Action Status',
						message: 'Remove failed. Error: ' + e.message
					});
				}

			}).catch(() => {
				this.$notify.warning({
					title: 'Action Status',
					message: 'Remove canceled'
				});
			});
		}
	}
}
</script>
